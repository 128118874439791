import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  reponse: any;
  repmessage: string;

  constructor(
    private router: Router,
    private authService: AuthService) {
    const jwtData = new FormData;
    jwtData.append('jwt', localStorage.getItem('jwt'));
    this.authService.repdecodejwt(jwtData).subscribe(rep => {
      this.reponse = rep;
      this.repmessage = this.reponse.message;
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('isConnected') === 'true') {
      return true;
    } else {
      this.router.navigateByUrl('/auth');
      return false;
    }

    /*  if (this.repmessage === 'Access granted.') {
         return true;
       } else {
         this.router.navigateByUrl('/auth');
         return false;
       }*/
  }


}
